import React from "react";
import { FaTelegram, FaTwitter } from "react-icons/fa";

export default function Footer() {
   const iconFunc = (icons, link) => {
      return { icons, link };
   };
   const iconArr = [
      iconFunc(<FaTwitter />, "https://x.com/dogwifchainz"),
      iconFunc(<FaTelegram />, "https://t.me/chainzsolana"),
      // iconFunc("/asset/ethscan.png", "#"),
      // iconFunc("/asset/solscan.png", "#"),
   ];
   return (
      <div className="bg-black">
         <div className="flex container justify-between items-center p-3">
            <div className="logo flex items-center gap-2">
               <img
                  src="/asset/logo.jpeg"
                  alt="Yukan"
                  className="img-fluid rounded-full"
                  width={50}
                  height={50}
               />{" "}
               <span className="font-bold text-fuchsia-500 text-lg hidden md:block">DOGWIFCHAINZ</span>
            </div>

            <span className="text-white font-bold">Copyright © 2023</span>
            <div className="icon-wrap flex justify-end gap-3">
               {iconArr.map((i, index) => (
                  <a href={i.link} target="_blank" className="text-fuchsia-500" key={index}>
                     {i.icons}
                  </a>
               ))}
            </div>
         </div>
      </div>
   );
}

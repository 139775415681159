import React from "react";

export default function Intro() {
   return (
      <div className="intro bg-black text-teal-300">
         <div className="bg-wrap  min-h-[80vh] flex items-center justify-center py-32">
            <div className="container  mx-auto text-center">
               <div className="text-wrap">
                  {/* <span className="text-3xl text-ninja font-extrabold">BRAAAA!!!!!!!! HEARD OF </span> <br /> */}
                  <span className="sm:text-9xl text-7xl text-happy">
                     {" "}
                     {/* <span className="text-ninja2">$</span>
                     <span className="font-extrabold text-fuchsia-500">CHAINZ</span> */}
                  </span>
               </div>
               {/* <div className="text-wrap ">
                  <span className="font-bold text-3xl text-pri text-ninja2">
                     I thought I said BUY! 
                  </span>{" "}
                  <br />
               </div> */}
            </div>
         </div>
      </div>
   );
}

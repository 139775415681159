import React from "react";
import About from "./components/About";
import Dog from "./components/Dog";
import Footer from "./components/Footer";
import Header from "./components/Header";
import Intro from "./components/Intro";
import Roadmap from "./components/Roadmap";
import Tokenomics from "./components/Tokenomics";

export default function Home() {
   return (
      <>
         <Header />
         <Intro />
         <About />
         <Tokenomics />
         <Dog />
         <Roadmap />
         <Footer />
      </>
   );
}

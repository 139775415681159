import React from "react";
import { FaBox, FaCircle } from "react-icons/fa";

export default function Tokenomics() {
   return (
      <div className="tk text-happy flex items-center min-h-screen text-neutral-200">
         <div className="bg-wrap col-12 min-h-screen py-32">
            <div className="container">
               <div className="col-md-8 mx-auto">
                  <div className="heading text-center py-3">
                     <span className="font-extrabold text-5xl md:text-7xl">
                        CHAINZWIFONOMICs
                     </span>
                  </div>

                  {/* Percentage */}

                  <div className="flex flex-wrap rounded-3xl overflow-hidden my-5 mb-3">
                     <div className="box1 relative bg-fuchsia-500 h-[50px] w-[2%]"></div>
                     <div className="box2 relative bg-yellow-500 h-[50px] w-[8%]"></div>
                     <div className="box2 relative bg-green-500 h-[50px] w-[90%]"></div>
                  </div>

                  <div className="percent mb-5">
                     <div className="flex font-bold px-4">
                        <div className="wrap col flex items-center gap-4">
                           <FaCircle className="text-fuchsia-500" />{" "}
                           <span>2% Marketing</span>
                        </div>
                        <div className="wrap col flex items-center gap-4">
                           <FaCircle className="text-yellow-500" />{" "}
                           <span>8% burn</span>
                        </div>
                        <div className="wrap col flex items-center gap-4">
                           <FaCircle className="text-green-500" />{" "}
                           <span>90% Liquidity Pool</span>
                        </div>
                     </div>
                  </div>

                  <div className="grid grid-cols-2 gap-4 text-black text-lg">
                     {/* Ticker */}
                     <div className="rounded-xl bg-fuchsia-500 text-center text-hanzu-yellow p-4 leading-8">
                        <span className="font-bold">Ticker</span> <br />
                        <span className="font-thin">$CHAINZ</span>
                     </div>

                     {/* Name */}
                     <div className="rounded-xl bg-yellow-500 text-center text-hanzu-yellow p-4 leading-8">
                        <span className="font-bold">Name</span> <br />
                        <span className="font-thin">Dogwifchainz</span>
                     </div>

                     {/* Token Supply */}
                     <div className="rounded-xl bg-blue-500 text-center text-hanzu-yellow p-4 leading-8 col-span-2">
                        <span className="font-bold">Token Supply</span> <br />
                        <span className="font-thin">
                           100 Million 
                        </span>
                     </div>
                  </div>

                  {/* Caption */}
                  {/* <div className="text-wrap mt-36">
                     <span className="text-sm text-white">
                        90% Liquidity Pool (LP): As deep and mysterious as the
                        ocean, ensuring unwavering stability. 10% Marketing: The
                        drumbeat of our war cry, echoing through the crypto
                        valleys.
                     </span>
                  </div> */}
               </div>
            </div>
         </div>
      </div>
   );
}

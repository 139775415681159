import React from "react";

export default function About() {
   return (
      <div className="wrap text-neutral-200 py-32">
         <div className="container">
            <div className="row">
               <div className=" flex items-center col-lg-6 leading-9 mb-4">
                  <div className="wrap">
                     <span className="font-extrabold text-lg bg-fuchsia-500 p-2">
                        About Dogwifchainz
                     </span>{" "}
                     <br />
                     <span className="text-3xl text-happy">
                        $CHAINZ is the realest meme project, droppin' love for
                        our canines at their peak game! Ride with us for a daily
                        dose of lit doggy hilarity, catching that real charm and
                        humor that makes our furry homies stand out. 
                     </span>
                     <div className="btn-wrap  mt-5  ">
                        <a
                           href="https://raydium.io/swap/?outputCurrency=3TswtN329cekJroUXKwLxMoZwDGk8sHvEypdjLBveaJp"
                           target="_blank"
                           className="p-3 bg-fuchsia-500 text-white rounded-3xl text-lg font-bold text-center"
                        >
                           Buy $CHAINZ
                        </a>
                        <a
                           href="#"
                           className="p-3 border-1 ml-3 border-fuchsia-500 text-fuchsia-500 rounded-3xl text-lg font-bold text-center"
                        >
                           Read Whitepaper
                        </a>
                     </div>
                  </div>
               </div>
               <div className="col-lg-6">
                  <img
                     src="/asset/ddc5.png"
                     alt="doggy"
                     className="img-fluid"
                  />
               </div>
            </div>
         </div>
      </div>
   );
}

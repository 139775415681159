import React from "react";

export default function Dog() {
   const dum = [
      "ddc.jpeg",
      "ddc1.jpeg",
      "ddc2.jpeg",
      "ddc3.jpeg",
      "ddc4.png",
      "ddc5.png",
      "ddc2.jpeg",
      "ddc1.jpeg",
      "ddc2.jpeg",
   ];
   return (
      <div className="mb-10 container relative">
         {/* <img src="/asset/chain.jpeg" alt="Chain" className="absolute top-0 right-0 w-[300px]" />
        <img src="/asset/chain.jpeg" alt="Chain" className="absolute bottom-0 left-0 w-[300px]"  /> */}

         <div className="row ">
            {dum.map((i, index) => (
               <div
                  className="col-md-3 mb-3 col-6 flex gap-2 items-center justify-between"
                  key={index}
               >
                  <img src={`/dog/${i}`} alt="doggy" />{" "}
               </div>
            ))}
         </div>
      </div>
   );
}

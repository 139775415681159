import React from "react";

export default function Roadmap() {
   const dum = [1, 2, 3];
   return (
      <div className="wrap py-10 text-neutral-200 text-happy">
         <div className="container">
            <div className="heading text-center text-7xl font-extrabold mb-4">
               <span>ROADMAP</span>
            </div>

            <div className="card-wrap">
               <div className="grid  md:grid-cols-3 gap-3">
                  {dum.map((i, index) => (
                     <div
                        className="card p-4 rounded-lg text-white bg-neutral-900"
                        key={index}
                     >
                        <div className="head text-xl font-bol">
                           <span>PHASE {index + 1}</span>
                        </div>
                        <div className="text-wrap text-neutral-400 text-center">
                           Coming soon..
                        </div>
                     </div>
                  ))}
               </div>
            </div>
         </div>
      </div>
   );
}

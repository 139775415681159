import React from 'react'
import { FaBars } from 'react-icons/fa'

export default function Header() {
    const toggleMenu = () => {
        document.querySelector(".menu-link").classList.toggle("active-link")
    }
  return (
    <div className="header text-neutral-100 shadow-sm py-3 text-happy">
        <div className="container">
            <div className="row align-items-center">
                <div className="col">
                    <div className="logo flex items-center gap-2">
                        <img src="/asset/logo.jpeg" alt="Yukan" className='img-fluid rounded-full' width={50} height={50}/> <span className='font-bold text-lg'>DOGWIFCHAINZ</span>
                    </div>
                </div>
                <div className="col">
                    <div className='menu-link d-lg-flex justify-content-evenly fw-bold'>
                        <a href="#" onClick={toggleMenu}>Home</a>
                        <a href="#about" onClick={toggleMenu}>About</a>
                        <a href="#tokenomics" onClick={toggleMenu}>Tokenomics</a>
                        <a href="#roadmap" onClick={toggleMenu}>Roadmap</a>
                        {/* <a href="#" onClick={toggleMenu}>Home</a> */}
                    </div>
                </div>
                <div className="col btn-con">
                    <div className=" d-flex justify-content-end">
                        <a href="https://raydium.io/swap/?outputCurrency=3TswtN329cekJroUXKwLxMoZwDGk8sHvEypdjLBveaJp" className='btn btn-sm font-bold px-4 py-2 rounded-pill bg-fuchsia-500'> Buy Now</a>
                    </div>
                </div>
               <div className="col menu-bar flex items-center justify-end">
               <div className="menu-bar text-right" onClick={toggleMenu}>
                    <i className='fw-bold fs-1 flex items-center justify-end'><FaBars /></i>
                </div>
               </div>
            </div>
        </div>
    </div>
  )
}